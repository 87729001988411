import { useState, useEffect, useContext } from 'react';
import NasabaContext from '../context/database';
import { auth } from '../services/database';

export default function useAuthListener() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('authUser')));
  useEffect(() => {
    let mounted = true;
    // localStorage.removeItem('authUser');
    function verifUser() {
      const _user = JSON.parse(localStorage.getItem('authUser'));
      if (mounted) {
        // localStorage.setItem('authUser', JSON.stringify(authUser));
        if (_user) {
          console.log('MMY USER in listener: ', _user);
          setUser(_user);
        } else setUser(null);
      }
    }
    if (mounted) {
      verifUser();
    }
    // eslint-disable-next-line no-return-assign
    return () => (mounted = false);
  }, [setUser]);
  return { user, setUser };
}

/*   useEffect(() => {
    let mounted = true;
    auth('Joa', 'Joaquim').then((authUser) => {
      if (mounted) {
        // localStorage.setItem('authUser', JSON.stringify(authUser));
        console.log('MMY USER: ', authUser);
        setUser(authUser);
      }
    });
    // eslint-disable-next-line no-return-assign
    return () => (mounted = false);
  }, []); */
