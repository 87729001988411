import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useHistory } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import useAuthListener from '../hooks/use-auth-listener';

export default function ProtectedRoute({ user, children, ...rest }) {
  const [_user, setUser] = useState();
  console.log('USER HEEE', user);
  const ref = useRef(null);
  useEffect(() => {
    let mounted = true;
    async function set() {
      setUser(true);
    }
    if (mounted) {
      set();
    }
    // eslint-disable-next-line no-return-assign
    return () => (mounted = false);
  }, [ref]);

  return (
    <Route
      ref={ref}
      {...rest}
      render={({ location }) => {
        if (user) {
          console.log('USER IN PROTECETD ROUTE', user);
          console.log('redirection to ', children);
          return React.cloneElement(children, { user });
        }

        if (!user) {
          console.log('USER NOT AVAILABLE', user);
          return (
            <Redirect
              to={{
                pathname: ROUTES.LOGIN,
                state: { from: location }
              }}
            />
          );
        }

        return null;
      }}
    />
  );
}

ProtectedRoute.propTypes = {
  user: PropTypes.object,
  children: PropTypes.object.isRequired
};
