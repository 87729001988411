import { API_URL } from '../constants/url';

function twoDigits(d) {
  if (d >= 0 && d < 10) return `0${d.toString()}`;
  if (d > -10 && d < 0) return `-0${(-1 * d).toString()}`;
  return d.toString();
}

// eslint-disable-next-line no-extend-native
Date.prototype.toMysqlFormat = function () {
  return `${this.getUTCFullYear()}-${twoDigits(1 + this.getUTCMonth())}-${twoDigits(
    this.getUTCDate()
  )} ${twoDigits(this.getUTCHours())}:${twoDigits(this.getUTCMinutes())}:${twoDigits(
    this.getUTCSeconds()
  )}`;
};

export async function auth(pseudonyme, password) {
  return fetch(`${API_URL}login.php`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ login: pseudonyme, motDePasse: password })
  }).then((data) => data.json());
}

export async function updateProfilPublic(
  _id,
  _pseudo,
  _description,
  typeMedia,
  mediaObject,
  _nomUser
) {
  const url = `${API_URL}modifierProfil.php`;
  const form = new FormData();
  // The file field here is determined by the uploaded api, can be other values
  form.append('file', mediaObject);
  form.append('pseudo', _pseudo);
  form.append('description', _description);
  form.append('typeMedia', typeMedia);
  form.append('id', _id);
  form.append('nomUser', _nomUser);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((data) => data.json());
}

export async function updateProfilPersonelle(
  _id,
  _nom,
  _postnom,
  _sexe,
  _email,
  _lieuNaissance,
  _dateNaissance,
  _nationalite,
  _telephone,
  _etatCivil
) {
  console.log(
    JSON.stringify({
      id: _id,
      nom: _nom,
      postnom: _postnom,
      sexe: _sexe,
      email: _email,
      lieuNaissance: _lieuNaissance,
      dateNaissance: _dateNaissance,
      nationalite: _nationalite,
      telephone: _telephone,
      etatCivil: _etatCivil,
      pseudo: null,
      description: null,
      photo: null
    })
  );
  return fetch(`${API_URL}modifierProfil.php`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id: _id,
      nom: _nom,
      postnom: _postnom,
      sexe: _sexe,
      email: _email,
      lieuNaissance: _lieuNaissance,
      dateNaissance: _dateNaissance,
      nationalite: _nationalite,
      telephone: _telephone,
      etatCivil: _etatCivil,
      pseudo: null,
      description: null,
      photo: null
    })
  }).then((data) => {
    console.log('data received', data);
    return data.json();
  });
}

export async function updatePassword(_id, _oldPassword, _password) {
  return fetch(`${API_URL}modifierPassword.php`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id: _id,
      ancienMotDePasse: _oldPassword,
      nouveauMotDePasse: _password
    })
  }).then((data) => data.json());
}

export async function updateProfilAddresse(
  _id,
  _pays,
  _province,
  _villeActuelle,
  _commune,
  _quartier,
  _cellule,
  _avenue,
  _numero,
  _codePostal
) {
  console.log(
    JSON.stringify({
      id: _id,
      nom: null,
      postnom: null,
      sexe: null,
      email: null,
      lieuNaissance: null,
      dateNaissance: null,
      nationalite: null,
      telephone: null,
      etatCivil: null,
      pseudo: null,
      description: null,
      photo: null,
      pays: _pays,
      province: _province,
      villeActuelle: _villeActuelle,
      commune: _commune,
      quartier: _quartier,
      cellule: _cellule,
      avenue: _avenue,
      numero: _numero,
      codePostal: _codePostal
    })
  );
  return fetch(`${API_URL}modifierProfil.php`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id: _id,
      nom: null,
      postnom: null,
      sexe: null,
      email: null,
      lieuNaissance: null,
      dateNaissance: null,
      nationalite: null,
      telephone: null,
      etatCivil: null,
      pseudo: null,
      description: null,
      photo: null,
      pays: _pays,
      province: _province,
      villeActuelle: _villeActuelle,
      commune: _commune,
      quartier: _quartier,
      cellule: _cellule,
      avenue: _avenue,
      numero: _numero,
      codePostal: _codePostal
    })
  }).then((data) => {
    console.log('data received', data);
    return data.json();
  });
}

export async function createNewPost(media, typeMedia, message, date, idUser) {
  const url = `${API_URL}creerPost.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('file', media);
  form.append('message', message);
  form.append('datetime', date.toMysqlFormat());
  form.append('typeMedia', typeMedia);
  form.append('idUser', idUser);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function registerUser(user) {
  const url = `${API_URL}enregistrerUtilisateur.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('nom', user.nom);
  form.append('postnom', user.postnom);
  form.append('prenom', user.prenom);
  form.append('sexe', user.sexe);
  form.append('dateNaissance', new Date(user.dateNaissance).toMysqlFormat());
  form.append('email', user.email);
  form.append('telephone', user.telephone);
  form.append('motDePasse', user.motDePasse);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function verifierExistanceUser(user) {
  const url = `${API_URL}verifierExistanceUser.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('nom', user.nom);
  form.append('postnom', user.postnom);
  form.append('prenom', user.prenom);
  form.append('sexe', user.sexe);
  form.append('dateNaissance', new Date(user.dateNaissance).toMysqlFormat());
  form.append('email', user.email);
  form.append('telephone', user.telephone);
  form.append('motDePasse', user.motDePasse);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}
export async function disconnect(idUser) {
  const url = `${API_URL}deconnection.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('idUser', idUser);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}
export async function getPosts(idUser, _page, idFamille) {
  const url = `${API_URL}getPosts.php`;
  console.log('req', JSON.stringify({ userId: idUser, page: _page }));

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ userId: idUser, page: _page, userIdFamille: idFamille })
  }).then((data) => data.json());
}

export async function getJournals(idUser, _page) {
  const url = `${API_URL}getJournals.php`;
  // console.log('req', JSON.stringify({ userId: idUser, page: _page }));

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ userId: idUser, page: _page })
  }).then((data) => data.json());
}

export async function getJournalsProfil(idUser, _page) {
  const url = `${API_URL}getJournalsProfil.php`;
  // console.log('req', JSON.stringify({ userId: idUser, page: _page }));

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ userId: idUser, page: _page })
  }).then((data) => data.json());
}

export async function partagerJournal(idJournal, _partager) {
  const url = `${API_URL}partagerJournal.php`;

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ journalId: idJournal, partager: _partager })
  }).then((data) => data.json());
}

export async function getLastJournal(idUser) {
  const url = `${API_URL}getLastJournal.php`;
  console.log('req journal', JSON.stringify({ userId: idUser }));

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ userId: idUser })
  }).then((data) => data.json());
}

export async function getConnectedFamilyMembers(idUser, familyId) {
  const url = `${API_URL}getConnectedFamilyMembers.php`;
  console.log('req connected memebers', JSON.stringify({ userId: idUser, idFamily: familyId }));

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ userId: idUser, idFamily: familyId })
  }).then((data) => data.json());
}

export async function searchUsers(_word, _page) {
  const url = `${API_URL}searchUsers.php`;
  // console.log('req journal', JSON.stringify({ userId: idUser, idFamily: familyId }));

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ word: _word, page: _page })
  }).then((data) => data.json());
}

export async function getConnaissances(idUser, familyId) {
  const url = `${API_URL}getConnaissances.php`;
  // console.log('req journal', JSON.stringify({ userId: idUser, idFamily: familyId }));

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ userId: idUser, idFamily: familyId })
  }).then((data) => data.json());
}

export async function createNewJournal(description, date, idUser) {
  const url = `${API_URL}creerJournal.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('description', description);
  form.append('datetime', date.toMysqlFormat());
  form.append('idUser', idUser);
  form.append('title', null);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function deletePost(idPost, idUser) {
  const url = `${API_URL}deletePost.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('idPost', idPost);
  form.append('idUser', idUser);

  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function deleteJournal(idJournal, idUser) {
  const url = `${API_URL}deleteJournal.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('idJournal', idJournal);
  form.append('idUser', idUser);

  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function repartagerPost(idPost, idUser) {
  const url = `${API_URL}repostSouvenir.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('idPost', idPost);
  form.append('idUser', idUser);
  form.append('date', new Date().toMysqlFormat());

  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function verifyCodeUser(email, code) {
  const url = `${API_URL}verifyCodeUser.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('email', email);
  form.append('code', code);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function reinitialiserMotDePasse(email) {
  const url = `${API_URL}resetPassword.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('email', email);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}
export async function resetPassword(password, email, code) {
  const url = `${API_URL}resetPasswordFin.php`;
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    // eslint-disable-next-line object-shorthand
    body: JSON.stringify({ password: password, email: email, code: code })
  }).then((data) => data.json());
}
export async function updateJournal(description, date, idUser, idJournal) {
  const url = `${API_URL}updateJournal.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('description', description);
  form.append('datetime', date.toMysqlFormat());
  form.append('idUser', idUser);
  form.append('title', null);
  form.append('idJournal', idJournal);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function createNewComment(idPost, idUser, message, date) {
  const url = `${API_URL}creerCommentaire.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('message', message);
  form.append('datetime', date.toMysqlFormat());
  form.append('idPost', idPost);
  form.append('idUser', idUser);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function envoyerMessage(idUserFrom, idUserTo, message, date) {
  const url = `${API_URL}envoyerMessage.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('message', message);
  form.append('datetime', date.toMysqlFormat());
  form.append('idUserFrom', idUserFrom);
  form.append('idUserTo', idUserTo);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function envoyerDemande(idUserFrom, idUserTo, status) {
  const url = `${API_URL}envoyerDemande.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('idUserFrom', idUserFrom);
  form.append('idUserTo', idUserTo);
  form.append('status', status);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}
export async function accepterDemande(idUserFrom, idUserTo, status) {
  const url = `${API_URL}accepterDemande.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('idUserFrom', idUserFrom);
  form.append('idUserTo', idUserTo);
  form.append('status', status);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function refuserDemande(idUserFrom, idUserTo, status) {
  const url = `${API_URL}refuserDemande.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('idUserFrom', idUserFrom);
  form.append('idUserTo', idUserTo);
  form.append('status', status);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function getChats(_idUserFrom, _idUserTo) {
  const url = `${API_URL}getChats.php`;

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ idUserFrom: _idUserFrom, idUserTo: _idUserTo })
  }).then((res) => res.json());
}

export async function getChatIndex(_idUser) {
  const url = `${API_URL}getChatIndex.php`;

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ idUser: _idUser })
  }).then((res) => res.json());
}

export async function creerFamille(nom, village, description) {
  const url = `${API_URL}creerFamille.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('nom', nom);
  form.append('village', village);
  form.append('description', description);
  // eslint-disable-next-line no-restricted-syntax
  /* for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  } */
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function updateHasChild(idParent, idPartenaire) {
  const url = `${API_URL}updateHasChild.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('idParent', idParent);
  form.append('idPartenaire', idPartenaire);
  // eslint-disable-next-line no-restricted-syntax
  /* for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  } */
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function deleteMembre(id, idParent, isParent) {
  const url = `${API_URL}deleteMembre.php`;
  const form = new FormData();

  // The file field here is determined by the uploaded api, can be other values
  form.append('idParent', idParent);
  form.append('id', id);
  form.append('isParent', isParent);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function creerMembreFamille(mediaObject, membre, typeMedia) {
  const url = `${API_URL}creerMembreFamille.php`;
  const form = new FormData();
  console.log(membre);
  // The file field here is determined by the uploaded api, can be other values
  form.append('file', mediaObject);
  form.append('firstname', membre.firstname);
  form.append('lastname', '');
  form.append('typeMedia', typeMedia);
  form.append('idUser', membre.idUser);
  form.append('sexe', membre._sexe);
  form.append('has_wife', membre.has_wife);
  form.append('has_husband', membre.has_husband);
  form.append('has_parent', membre.has_parent);
  form.append('has_child', membre.has_child);
  form.append('idParent', membre._idParent);
  form.append('idFamille', membre.idFamille);
  form.append('partenaire', membre.idPartenaire);
  form.append('isParent', membre._isParent);
  form.append('currentUser', membre._currentUser);
  // eslint-disable-next-line no-restricted-syntax
  /*
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  } */
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function updateEpouseMembre(mediaObject, membre, typeMedia) {
  const url = `${API_URL}updateEpouseMembre.php`;
  const form = new FormData();
  console.log(membre);
  // The file field here is determined by the uploaded api, can be other values
  form.append('file', mediaObject);
  form.append('firstname', membre.firstname);
  form.append('lastname', '');
  form.append('typeMedia', typeMedia);
  form.append('sexe', membre._sexe);
  form.append('has_wife', membre.has_wife);
  form.append('has_husband', membre.has_husband);
  form.append('has_parent', membre.has_parent);
  form.append('has_child', membre.has_child);
  form.append('partenaire', membre.idPartenaire);
  form.append('id', membre.idMembre);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function updateInfoMembre(mediaObject, membre, typeMedia) {
  const url = `${API_URL}updateInfoMembre.php`;
  const form = new FormData();
  console.log(membre);
  // The file field here is determined by the uploaded api, can be other values
  form.append('firstname', membre.firstname);
  form.append('lastname', '');
  form.append('sexe', membre._sexe);
  form.append('has_wife', membre.has_wife);
  form.append('has_husband', membre.has_husband);
  form.append('partenaire', membre.idPartenaire);
  form.append('id', membre._idMembre);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function updatePictureMembre(mediaObject, typeMedia, idMembre, nomMembre) {
  const url = `${API_URL}UpdatePhotoMembre.php`;
  const form = new FormData();
  // The file field here is determined by the uploaded api, can be other values
  form.append('file', mediaObject);
  form.append('nomMembre', nomMembre);
  form.append('typeMedia', typeMedia);
  form.append('idMembre', idMembre);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function updatePartenaireMembreFamille(idMembre, idPartenaire) {
  const url = `${API_URL}updatePartenaireMembreFamille.php`;
  const form = new FormData();
  // The file field here is determined by the uploaded api, can be other values
  form.append('idMembre', idMembre);
  form.append('idPartenaire', idPartenaire);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function getGenealogy(idUser) {
  const url = `${API_URL}getGenealogy.php`;
  // console.log('req', JSON.stringify({ userId: idUser }));

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ userId: idUser })
  }).then((data) => data.json());
}

export async function likePost(idPost, idUser) {
  const url = `${API_URL}likePost.php`;
  const form = new FormData();

  form.append('idPost', idPost);
  form.append('idUser', idUser);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function unlikePost(idPost, idUser) {
  const url = `${API_URL}unlikePost.php`;
  const form = new FormData();

  form.append('idPost', idPost);
  form.append('idUser', idUser);
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of form.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }
  return fetch(url, {
    method: 'POST',
    body: form
  }).then((res) => res.json());
}

export async function updateProfilLife(
  _id,
  _vieProfessionnelle,
  _vieSpirituelle,
  _biographie,
  _histoirePersonnelle
) {
  return fetch(`${API_URL}modifierProfil.php`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id: _id,
      nom: null,
      postnom: null,
      sexe: null,
      email: null,
      lieuNaissance: null,
      dateNaissance: null,
      nationalite: null,
      telephone: null,
      etatCivil: null,
      pseudo: null,
      description: null,
      photo: null,
      pays: null,
      province: null,
      villeActuelle: null,
      commune: null,
      quartier: null,
      cellule: null,
      avenue: null,
      numero: null,
      codePostal: null,
      vieProfessionnelle: _vieProfessionnelle,
      vieSpirituelle: _vieSpirituelle,
      biographie: _biographie,
      histoirePersonnelle: _histoirePersonnelle
    })
  }).then((data) => {
    console.log('data received', data);
    return data.json();
  });
}

export async function getUserByUserId(_userId) {
  console.log('get user by user Id: ', _userId);
  return fetch(`${API_URL}getUserById.php`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ userId: _userId })
  }).then((data) => data.json());
}

export async function getDemandesUser(_userId) {
  console.log('get Demandes user: ', _userId);
  return fetch(`${API_URL}getDemandes.php`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ idUser: _userId })
  }).then((data) => data.json());
}

export async function getUserByPseudo(_pseudo) {
  console.log('get user by user pseudo: ', _pseudo);
  return fetch(`${API_URL}getUserByPseudo.php`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ pseudo: _pseudo })
  }).then((data) => data.json());
}

export async function getUserByUsername(username) {
  const user = {
    name: 'Kambale',
    firstname: 'Abednego',
    lastname: 'Wamuhindo',
    emailAddress: 'abednegokam@gmail.com',
    phoneNumber: '+243995530349',
    sex: 'M',
    birthdate: '11.11.1970',
    userId: '1234'
  };

  return user;
}

// get user from the firestore where userId === userId (passed from the auth)
export async function getUserByUserId2(userId) {
  const user = {
    name: 'Kambale',
    firstname: 'Abednego',
    lastname: 'Wamuhindo',
    emailAddress: 'abednegokam@gmail.com',
    phoneNumber: '+243995530349',
    sex: 'M',
    birthdate: '11.11.1970',
    userId: '1234',
    displayName: 'Kambale Abednego',
    families: ['1', '2', '3', '4', '5'],
    wife: 'Constante'
  };

  return user;
}

export async function getPhotos(userId, following) {
  const photosWithUserDetails = [];

  return photosWithUserDetails;
}

// check all conditions before limit results
export async function getSuggestedProfiles(userId, following) {
  const profiles = [];
  return profiles;
}

export async function getUserPhotosByUserId(userId) {
  const photos = [];
  return photos;
}

export async function doesUsernameExist(username) {
  const result = [];

  return result.length > 0;
}

export function getConnectedMembers(userId) {
  const members = [
    {
      name: 'Chiza',
      lastname: 'matunguru',
      id: '1'
    },
    {
      name: 'Chikuru',
      lastname: 'matunguru',
      id: '2'
    },
    {
      name: 'Chidoro',
      lastname: 'matunguru',
      id: '3'
    },
    {
      name: 'Amina',
      lastname: 'matunguru',
      id: '4'
    },
    {
      name: 'Chikwanine',
      lastname: 'matunguru',
      id: '5'
    },
    {
      name: 'Wema',
      lastname: 'matunguru',
      id: '6'
    }
  ];
  return members;
}

export function getLastJournals(userId) {
  const lastJournal = {
    content:
      "Que c'était beau cette experience. Que c'était beau cette experience.Que c'était beau cette experience. Que c'était beau cette experience.Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience.",
    date: new Date()
  };
  return lastJournal;
}

export function getJournalContents(userId) {
  const journals = [
    {
      content:
        "Que c'était beau cette experience. Que c'était beau cette experience.Que c'était beau cette experience. Que c'était beau cette experience.Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience.",
      date: new Date(),
      id: '1'
    },
    {
      content:
        "Que c'était beau cette experience. Que c'était beau cette experience.Que c'était beau cette experience. Que c'était beau cette experience.Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience.",
      date: new Date(),
      id: '2'
    },
    {
      content:
        "Que c'était beau cette experience. Que c'était beau cette experience.Que c'était beau cette experience. Que c'était beau cette experience.Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience.",
      date: new Date(),
      id: '3'
    },
    {
      content:
        "Que c'était beau cette experience. Que c'était beau cette experience.Que c'était beau cette experience. Que c'était beau cette experience.Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience.",
      date: new Date(),
      id: '4'
    },
    {
      content:
        "Que c'était beau cette experience. Que c'était beau cette experience.Que c'était beau cette experience. Que c'était beau cette experience.Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience. Que c'était beau cette experience.",
      date: new Date(),
      id: '5'
    }
  ];
  return journals;
}

export function getPostContents(userId) {
  const contents = [
    {
      name: 'Chiza',
      lastname: 'matunguru',
      imageSrc: `/images/users/raphael/3.jpg`,
      likes: [],
      userLikedPhto: true,
      docId: '1',
      postMessage: 'Beautiful moment with this animal',
      comments: [
        {
          comment: 'I really like it!!',
          displayName: 'Chidoro Matunguru'
        },
        {
          comment: 'I really like it!!',
          displayName: 'Chikuru Matunguru'
        },
        {
          comment: 'Thanks chikuru!!',
          displayName: 'Chiza Matunguru'
        },
        {
          comment: 'Acha buyuai!!',
          displayName: 'Amina Matunguru'
        }
      ],
      dateCreated: new Date()
    },
    {
      name: 'Chikuru',
      lastname: 'matunguru',
      imageSrc: `/images/users/raphael/1.jpg`,
      likes: [],
      userLikedPhto: true,
      docId: '2',
      postMessage:
        "Que c'était beau cette experience.Que c'était beau cette experience.Que c'était beau cette experience.Que c'était beau cette experience.Que c'était beau cette experience.",
      comments: [
        {
          comment: 'I really like it!!',
          displayName: 'Chidoro Matunguru'
        },
        {
          comment: 'I really like it!!',
          displayName: 'Chikuru Matunguru'
        },
        {
          comment: 'Thanks chikuru!!',
          displayName: 'Chiza Matunguru'
        },
        {
          comment: 'Acha buyuai!!',
          displayName: 'Amina Matunguru'
        }
      ],
      dateCreated: new Date()
    },
    {
      name: 'Amina',
      lastname: 'matunguru',
      imageSrc: `/images/users/raphael/2.jpg`,
      likes: [],
      userLikedPhto: true,
      docId: '3',
      postMessage:
        "Que c'était beau cette experience.Que c'était beau cette experience.Que c'était beau cette experience.Que c'était beau cette experience.Que c'était beau cette experience.",
      comments: [
        {
          comment: 'I really like it!!',
          displayName: 'Chidoro Matunguru'
        },
        {
          comment: 'I really like it!!',
          displayName: 'Chikuru Matunguru'
        },
        {
          comment: 'Thanks chikuru!!',
          displayName: 'Chiza Matunguru'
        },
        {
          comment: 'Acha buyuai!!',
          displayName: 'Amina Matunguru'
        }
      ],
      dateCreated: new Date()
    }
  ];
  return contents;
}

export async function updateLoggedInUserFollowing(
  loggedInUserDocId, // currently logged in user document id (karl's profile)
  profileId, // the user that karl requests to follow
  isFollowingProfile // true/false (am i currently following this person?)
) {
  const a = 10;
}

export async function isUserFollowingProfile(loggedInUserUsername, profileUserId) {
  const response = {
    userId: '1234'
  };

  return response.userId;
}

export async function updateFollowedUserFollowers(
  profileDocId, // currently logged in user document id (karl's profile)
  loggedInUserDocId, // the user that karl requests to follow
  isFollowingProfile // true/false (am i currently following this person?)
) {
  const a = 10;
}

export async function toggleFollow(
  isFollowingProfile,
  activeUserDocId,
  profileDocId,
  profileUserId,
  followingUserId
) {
  // 1st param: karl's doc id
  // 2nd param: raphael's user id
  // 3rd param: is the user following this profile? e.g. does karl follow raphael? (true/false)
  await updateLoggedInUserFollowing(activeUserDocId, profileUserId, isFollowingProfile);

  // 1st param: karl's user id
  // 2nd param: raphael's doc id
  // 3rd param: is the user following this profile? e.g. does karl follow raphael? (true/false)
  await updateFollowedUserFollowers(profileDocId, followingUserId, isFollowingProfile);
}
