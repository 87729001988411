import { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Helmet from 'react-helmet';
import ReactLoader from './components/loader';
import * as ROUTES from './constants/routes';
import './styles/styles.css';
import UserContext from './context/user';
import useAuthListener from './hooks/use-auth-listener';

import ProtectedRoute from './helpers/protected-route';
import MyProtectedRoute from './helpers/my-protected-route copy';
import { resetPassword } from './services/database';

const Login = lazy(() => import('./pages/login'));
const SignUp = lazy(() => import('./pages/sign-up'));
const ConfCode = lazy(() => import('./pages/codeConfirmation'));
const Dashboard = lazy(() => import('./pages/dashboard'));
const Genealogy = lazy(() => import('./pages/genealogie'));
const Journal = lazy(() => import('./pages/journal'));
const Family = lazy(() => import('./pages/family'));
const Profile = lazy(() => import('./pages/profile'));
const ModifProfile = lazy(() => import('./pages/modifyProfile'));
const ModifPassword = lazy(() => import('./pages/modifyPassword'));
const ResetPassword = lazy(() => import('./pages/recoverPassword'));
const ResetPasswordFin = lazy(() => import('./pages/resetPasswordPage'));
const Profil = lazy(() => import('./pages/profil'));
const Connaissance = lazy(() => import('./pages/connaissance'));
const Conversation = lazy(() => import('./pages/chatIndex'));
const Chat = lazy(() => import('./pages/chat'));
const NotFound = lazy(() => import('./pages/not-found'));

export default function App() {
  const { user, setUser: setActiveUser } = useAuthListener();

  return (
    <UserContext.Provider value={{ user, setActiveUser }}>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      <Router>
        <Suspense fallback={<ReactLoader />}>
          <Switch>
            <ProtectedRoute user={user} path={ROUTES.DASHBOARD} exact>
              <Dashboard />
            </ProtectedRoute>
            <Route exact path="/">
              <Redirect to={ROUTES.DASHBOARD} />
            </Route>
            <Route path={ROUTES.LOGIN} component={Login} />
            <Route path={ROUTES.SIGN_UP} component={SignUp} />
            <Route path={ROUTES.CONF_CODE} component={ConfCode} />
            <Route path={ROUTES.RESET_PASSWORD_FIN} component={ResetPasswordFin} />
            <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
            <ProtectedRoute user={user} path={ROUTES.JOURNAL} exact>
              <Journal />
            </ProtectedRoute>
            <ProtectedRoute user={user} path={ROUTES.FAMILY} exact>
              <Family />
            </ProtectedRoute>
            <ProtectedRoute user={user} path={ROUTES.CHAT} exact>
              <Chat />
            </ProtectedRoute>
            <ProtectedRoute user={user} path={ROUTES.GENEALOGY} exact>
              <Genealogy />
            </ProtectedRoute>
            <ProtectedRoute user={user} path={ROUTES.MODIF_PROFILE} exact>
              <ModifProfile />
            </ProtectedRoute>
            <ProtectedRoute user={user} path={ROUTES.MODIF_PASSWORD} exact>
              <ModifPassword />
            </ProtectedRoute>
            <ProtectedRoute user={user} path={ROUTES.PROFILE} exact>
              <Profil />
            </ProtectedRoute>
            <ProtectedRoute user={user} path={ROUTES.CONNAISSANCE} exact>
              <Connaissance />
            </ProtectedRoute>
            <ProtectedRoute user={user} path={ROUTES.CONVERSATION} exact>
              <Conversation />
            </ProtectedRoute>
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Router>
    </UserContext.Provider>
  );
}
