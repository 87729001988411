export const DASHBOARD = '/dashboard';
export const JOURNAL = '/journal';
export const FAMILY = '/family';
export const GENEALOGY = '/family/genealogy';
export const LOGIN = '/login';
export const SIGN_UP = '/sign-up';
export const CONF_CODE = '/confirmation-code';
export const PROFILE = '/profile/:pseudonyme';
export const CHAT = '/chat/:pseudo';
export const CONVERSATION = '/chat';
export const NOT_FOUND = '/not-found';
export const MODIF_PROFILE = '/profile/modifyProfile';
export const MODIF_PASSWORD = '/profile/modifyPassword';
export const CONNAISSANCE = '/connaissance';
export const RESET_PASSWORD_FIN = '/reset-password/:emailCode';
export const RESET_PASSWORD = '/reset-password';
